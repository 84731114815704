import Vue from 'vue'
import {EagleFormLayerInterface} from 'components/form/types/form'

class paymentFormLayer {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : EagleFormLayerInterface {
    return {
      key: 'payment',
      blocks: [
        {
          title: 'ec.data.payment',
          grid: { xl: 6, lg: 6, md: 6 },
          data: {
            payment: {
              type: 'data-list',
              grid: { xl: 12, lg: 12, md: 12 },
              dataArray: (data: any) => {
                if(!data) return null
                return [
                  {
                    title: 'ec.data.payment_status',
                    data: this.vm.$t(`ec.data.payment_status.${data.status}`),
                    actionSlot: () => import('./paymentFormLayer/changePaymentStatus.vue'),
                  },
                  {
                    title: 'ec.data.payment_type',
                    data: this.vm.$t(`ec.data.payment_type.${data.type}`),
                  },
                  {
                    title: 'ec.data.provider',
                    data: this.vm.$t(`ec.data.payment.provider.${data.provider}`),
                  },
                  {
                    title: 'data.amount',
                    data: data.amount,
                  },
                  {
                    title: 'ec.data.payment_fee',
                    data: data.fee,
                  },
                  {
                    title: 'ec.data.payment.uid',
                    data: data.uid,
                    copyable: true,
                  },
                  {
                    title: 'ec.data.payment.service_order_id',
                    data: data.service_order_id,
                    copyable: true,
                  },
                  {
                    title: 'ec.data.payment.expired_at',
                    data: data.expired_at,
                    emptyText: 'data.none',
                  },
                  {
                    title: 'ec.data.payment.paid_at',
                    data: data.paid_at,
                    emptyText: 'data.none',
                  },
                ]
              },
            },
            'payment.info': {
              create: (formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return false
                if(!formInfo.formData.payment) return false
                if(!formInfo.formData.payment.info) return false
                return true
              },
              label: 'ec.data.payment.info',
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./paymentFormLayer/paymentInfo.vue'),
            }
          },
        },

        {
          title: 'ec.data.payment_log',
          grid: { xl: 6, lg: 6, md: 6 },
          data: {
            payment_logs: {
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./logs.vue'),
              meta: {
                color: 'green',
                logType: 'payment',
              },
            },
          },
        }
      ],
    }
  }
}

export default (vm: Vue, formKey: string) => new paymentFormLayer(vm, formKey)
