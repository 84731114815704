import Vue from 'vue'
import {EagleFormLayerInterface} from 'components/form/types/form'

class baseFormLayer {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : EagleFormLayerInterface {
    return {
      key: 'base',
      blocks: [
        {
          title: 'form_block.base',
          data: {
            id: {
              copyable: true,
              label: 'order.data.id',
              type: 'readonly-content',
            },
            status: {
              copyable: true,
              label: 'order.data.status',
              type: 'readonly-content',
              text: data => this.vm?.$t(`order.data.status.${data}`),
            },
            payment_status: {
              label: 'ec.data.payment_status',
              type: 'readonly-content',
              text: data => this.vm?.$t(`ec.data.payment_status.${data}`),
            },
            delivery_status: {
              label: 'ec.data.delivery_status',
              type: 'readonly-content',
              text: data => this.vm?.$t(`ec.data.delivery_status.${data}`),
            },
            amount: {
              label: 'order.data.amount',
              type: 'readonly-content',
            },
            amount_payable: {
              label: 'order.data.amount_payable',
              type: 'readonly-content',
            },
            payment_fee: {
              label: 'ec.data.payment_fee',
              type: 'readonly-content',
            },
            delivery_fee: {
              label: 'ec.data.delivery_fee',
              type: 'readonly-content',
            },
            note: {
              label: 'order.data.note',
              type: 'textarea',
              disabled: true,
              copyable: true,
              grid: { xl: 12, lg: 12, md: 12 },
              maxlength: 1000,
            },
            admin_note: {
              label: 'order.data.admin_note',
              titleSlot: () => import('./baseFormLayer/updateAdminNote.vue'),
              type: 'textarea',
              copyable: true,
              grid: { xl: 12, lg: 12, md: 12 },
              maxlength: 1000,
            },
            created_at: {
              label: 'data.created_at',
              type: 'readonly-content',
            },
            updated_at: {
              label: 'data.updated_at',
              type: 'readonly-content',
            },
          },
        },

        {
          title: 'order.data.buyer',
          data: {
            email: {
              label: 'data.email',
              type: 'readonly-content',
              copyable: true,
              route: (data: any, formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return null
                if(!formInfo.formData.member) return null
                return {
                  name: 'member-update',
                  params: { target: formInfo.formData.member.id },
                }
              }
            },
            name: {
              label: 'data.person_name',
              type: 'readonly-content',
              copyable: true,
              text: (data: any, formInfo: FormInfoInterface) => {
                if(!formInfo.formData) return null
                if(!formInfo.formData.member) return null
                return formInfo.formData.member.name || null
              },
            },
            phone: {
              label: 'data.phone',
              type: 'readonly-content',
              copyable: true,
            },
          },
        },
      ],
    }
  }
}

export default (vm: Vue, formKey: string) => new baseFormLayer(vm, formKey)
