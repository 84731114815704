import Vue from 'vue'
import {EagleFormLayerInterface} from 'components/form/types/form'

class deliverRequestLogsFormLayer {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : EagleFormLayerInterface {
    return {
      key: 'delivery_request_logs',
      create: (formInfo: FormInfoInterface) => {
        const deliveryRequestLogs = window.eagleLodash.get(formInfo, 'formData.delivery_request_logs')
        if(!Array.isArray(deliveryRequestLogs)) return false
        return deliveryRequestLogs.length > 0
      },
      blocks: [
        {
          data: {
            'delivery_request_logs': {
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./deliverRequestLogsFormLayer/deliveryRequestLogs.vue'),
            },
          },
        }
      ]
    }
  }
}

export default (vm: Vue, formKey: string) => new deliverRequestLogsFormLayer(vm, formKey)
