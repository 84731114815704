import Vue from 'vue'
import {EagleFormLayerInterface} from 'components/form/types/form'

class deliveryFormLayer {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : EagleFormLayerInterface {
    return {
      key: 'delivery',
      blocks: [
        {
          title: 'ec.data.delivery',
          grid: { xl: 6, lg: 6, md: 6 },
          // @ts-ignore
          titleSlot: () => import('./deliveryFormLayer/createDeliveryRequest.vue'),
          data: {
            delivery: {
              type: 'data-list',
              grid: { xl: 12, lg: 12, md: 12 },
              dataArray: (data: any) => {
                if(!data) return null
                return [
                  {
                    title: 'ec.data.delivery_status',
                    data: this.vm.$t(`ec.data.delivery_status.${data.status}`),
                    actionSlot: () => import('./deliveryFormLayer/changeDeliveryStatus.vue'),
                  },
                  {
                    title: 'ec.data.delivery_type',
                    data: this.vm.$t(`ec.data.delivery_type.${data.type}`),
                  },
                  {
                    title: 'ec.data.delivery.temperature',
                    data: (formInfo: FormInfoInterface) => {
                      const data = window.eagleLodash.get(formInfo, 'formData.delivery.options.temperature')
                      if(!data) return null
                      return `ec.data.delivery.temperature.${data}`
                    },
                    hiddenIfEmpty: true,
                  },
                  {
                    title: 'ec.data.delivery.period',
                    data: (formInfo: FormInfoInterface) => {
                      const data = window.eagleLodash.get(formInfo, 'formData.delivery.options.period')
                      if(!data) return null
                      return `ec.data.delivery.period.${data}`
                    },
                    emptyText: 'data.none',
                    hiddenIfEmpty: true,
                  },
                  {
                    title: 'ec.data.delivery.size',
                    data: (formInfo: FormInfoInterface) => {
                      const data = window.eagleLodash.get(formInfo, 'formData.delivery.options.size')
                      if(!data) return null
                      return `${data}cm`
                    },
                    emptyText: 'data.none',
                    hiddenIfEmpty: true,
                  },
                  {
                    title: 'ec.data.provider',
                    data: this.vm.$t(`ec.data.delivery.provider.${data.provider}`),
                  },
                  {
                    title: 'ec.data.delivery_fee',
                    data: data.fee,
                  },
                  {
                    title: 'ec.data.delivery.uid',
                    data: data.uid,
                    copyable: true,
                  },
                  {
                    title: 'ec.data.delivery.expired_at',
                    data: data.expired_at,
                    emptyText: 'data.none',
                    hiddenIfEmpty: true,
                  },
                  {
                    title: 'ec.data.delivery.delivered_at',
                    data: data.delivered_at,
                    emptyText: 'data.none',
                    hiddenIfEmpty: true,
                  },
                  {
                    title: 'ec.data.delivery.service_order_id',
                    data: data.service_order_id,
                    copyable: true,
                    emptyText: 'data.none',
                  },
                  {
                    title: 'ec.data.delivery.service_status',
                    data: data.service_status,
                    emptyText: 'data.none',
                    copyable: true,
                  },
                  {
                    title: 'ec.data.delivery.service_message',
                    data: data.service_message,
                    emptyText: 'data.none',
                  },
                ]
              },
            },
          },
        },

        {
          title: 'ec.data.delivery_log',
          grid: { xl: 6, lg: 6, md: 6 },
          data: {
            delivery_logs: {
              grid: { xl: 12, lg: 12, md: 12 },
              component: () => import('./logs.vue'),
              meta: {
                color: 'orange',
                logType: 'delivery',
              },
            },
          },
        }
      ],
    }
  }
}

export default (vm: Vue, formKey: string) => new deliveryFormLayer(vm, formKey)
