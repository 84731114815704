import Vue from 'vue'
import {EagleFormLayerInterface} from 'components/form/types/form'

class deliverInfoFormLayer {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : EagleFormLayerInterface {
    return {
      key: 'delivery_info',
      blocks: [
        {
          title: 'ec.data.delivery_info',
          titleSlot: () => import('./deliveryInfo/updateDeliveryInfo.vue'),
          data: {
            'delivery_info.name': {
              label: 'ec.data.delivery_info.name',
              type: 'readonly-content',
              copyable: true,
            },
            'delivery_info.phone': {
              label: 'ec.data.delivery_info.phone',
              type: 'readonly-content',
              copyable: true,
            },
            'delivery_info.email': {
              label: 'data.email',
              type: 'readonly-content',
              copyable: true,
            },
            'delivery_info.zip': {
              label: 'ec.data.zip',
              type: 'readonly-content',
              copyable: true,
              create: (formInfo: FormInfoInterface) => {
                const deliveryType = window.eagleLodash.get(formInfo, 'formData.delivery.type')
                // @ts-ignore
                return this.vm.$isHomeDeliveryType(deliveryType)
              },
            },
            'delivery_info.full_address': {
              label: 'ec.data.address',
              type: 'readonly-content',
              copyable: true,
              create: (formInfo: FormInfoInterface) => {
                const deliveryType = window.eagleLodash.get(formInfo, 'formData.delivery.type')
                // @ts-ignore
                return this.vm.$isHomeDeliveryType(deliveryType)
              },
            },
            'delivery_info.store_no': {
              label: 'ec.data.store_no',
              type: 'readonly-content',
              copyable: true,
              create: (formInfo: FormInfoInterface) => {
                const deliveryType = window.eagleLodash.get(formInfo, 'formData.delivery.type')
                // @ts-ignore
                return this.vm.$isCvsDeliveryType(deliveryType)
              },
            },
            'delivery_info.store_name': {
              label: 'ec.data.store_name',
              type: 'readonly-content',
              copyable: true,
              create: (formInfo: FormInfoInterface) => {
                const deliveryType = window.eagleLodash.get(formInfo, 'formData.delivery.type')
                // @ts-ignore
                return this.vm.$isCvsDeliveryType(deliveryType)
              },
            },
            'delivery_info.store_address': {
              label: 'ec.data.store_address',
              type: 'readonly-content',
              copyable: true,
              create: (formInfo: FormInfoInterface) => {
                const deliveryType = window.eagleLodash.get(formInfo, 'formData.delivery.type')
                // @ts-ignore
                return this.vm.$isCvsDeliveryType(deliveryType)
              },
            },
          },
        }
      ]
    }
  }
}

export default (vm: Vue, formKey: string) => new deliverInfoFormLayer(vm, formKey)
