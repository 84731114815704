import Vue from 'vue'
import { EagleFormConfigInterface } from '@/components/form/types/form'
import baseFormLayer from 'modules/order/views/orderForm/formLayer/baseFormLayer.ts'
import deliveryInfoFormLayer from 'modules/order/views/orderForm/formLayer/deliveryInfoFormLayer'
import itemsFormLayer from 'modules/order/views/orderForm/formLayer/itemsFormLayer'
import paymentFormLayer from 'modules/order/views/orderForm/formLayer/paymentFormLayer'
import deliveryFormLayer from 'modules/order/views/orderForm/formLayer/deliveryFormLayer'
import deliveryRequestLogsFormLayer from 'modules/order/views/orderForm/formLayer/deliveryRequestLogsFormLayer'
class formConfig {
  protected vm?: Vue
  protected formKey?: string

  _defaultData() {
    return {
      name: null,
    }
  }

  get(vm: Vue, formKey: string) : EagleFormConfigInterface {
    this.vm = vm
    this.formKey = formKey

    return {
      from: () => [
        { label: 'page.order', route: { name: 'order' } },
      ],
      listRoute: () => ({ name: 'order' }),
      tabs: this.tabs(),
      afterCreateRoute: result => ({
        name: 'order-update',
        params: { target: result.id },
      }),
      pageTitle: (formMeta: any, formData: AnyObject) => window.eagleLodash.get(formData, ['id']),
      pageTitleBase: () => 'module.order',
      defaultData: this._defaultData,
      layers: this.layers(),
      dataAction: {
        status: false,
        save: false,
        delete: false,
      },
      metaAction: {
        invalid: {
          component: () => import('./orderForm/metaAction/orderInvalid.vue'),
          create: (formInfo: FormInfoInterface) : boolean => {
            // @ts-ignore
            return window.eagleLodash.get(formInfo, 'formData.status') != this.vm.$orderConstants.STATUS_VOID
          },
        },
        contact: {
          component: () => import('./orderForm/metaAction/orderContact.vue'),
        },
        print: {
          label: 'ec.action.delivery-print',
          color: 'green',
          callback: (formInfo: FormInfoInterface) => {
            this.vm?.$apopup.base({
              title: this.vm.$t('ec.action.delivery-print'),
              applyCallback: () => {
                const route = {
                  name: 'ecpay-delivery-print',
                  params: { id: formInfo.formData.id },
                }
                const url = this.vm?.$router.resolve(route).href
                window.open(url)
              }
            })
          },
          small: false,
          create: (formInfo: FormInfoInterface) : boolean => {
            return !!window.eagleLodash.get(formInfo, 'formData.delivery.service_order_id')
          },
          buttonProperties: () => ({
            class: 'white--text',
          }),
        },
      },
    }
  }

  tabs() {
    const tabs = [
      { label: 'form_block.base', key: 'base' },
      { label: 'ec.data.delivery_info', key: 'delivery_info' },
      {
        label: 'ec.data.items',
        key: 'items',
        badge: (formInfo: FormInfoInterface) => {
          const orderStatus = window.eagleLodash.get(formInfo, 'formData.status')
          // @ts-ignore
          if(orderStatus === this.vm.$orderConstants.STATUS_CANCEL_REQUEST) return true

          // @ts-ignore
          if(orderStatus === this.vm.$orderConstants.STATUS_RETURN_REQUEST) return true
          return false
        },
      },
      { label: 'ec.data.payment', key: 'payment' },
      { label: 'ec.data.delivery', key: 'delivery' },
      {
        label: 'ec.data.delivery_request_logs',
        key: 'delivery_request_logs',
        create: (formInfo: FormInfoInterface) => {
          const deliveryRequestLogs = window.eagleLodash.get(formInfo, 'formData.delivery_request_logs')
          if(!Array.isArray(deliveryRequestLogs)) return false
          return deliveryRequestLogs.length > 0
        },
      }
    ]
    return tabs
  }

  layers() {
    if(!this.vm) return []
    if(!this.formKey) return []
    const layers =  [
      baseFormLayer(this.vm, this.formKey).get(),
      deliveryInfoFormLayer(this.vm, this.formKey).get(),
      itemsFormLayer(this.vm, this.formKey).get(),
      paymentFormLayer(this.vm, this.formKey).get(),
      deliveryFormLayer(this.vm, this.formKey).get(),
      deliveryRequestLogsFormLayer(this.vm, this.formKey).get(),
    ]
    return layers
  }
}

export default new formConfig()
