import Vue from 'vue'
import {EagleFormLayerInterface} from 'components/form/types/form'

class itemsFormLayer {
  protected vm: Vue
  protected formKey: string
  constructor(vm: Vue, formKey: string) {
    this.vm = vm
    this.formKey = formKey
  }

  get() : EagleFormLayerInterface {
    return {
      key: 'items',
      blocks: [
        {
          title: 'ec.data.items',
          data: {
            items: {
              grid: {xl: 12, lg: 12, md: 12},
              component: () => import('modules/order/views/orderForm/formLayer/items/items.vue'),
            },
          },
        },
      ],
    }
  }
}

export default (vm: Vue, formKey: string) => new itemsFormLayer(vm, formKey)
